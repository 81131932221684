import { apiRequest } from "./_api";

export async function postInboxMessages(
  createInboxMessagesDto: CreateInboxMessagesDto
): Promise<ReadInboxMessagesDto> {
  return await apiRequest<ReadInboxMessagesDto>(
    "post",
    `/inboxMessages/create`,
    createInboxMessagesDto
  );
}

export async function getInboxMessagesListAndSearch(
  page: number,
  itemsPerPage: number,
  search?: string
): Promise<ListInboxMessagesDto> {
  let query = `?page=${page}&itemsPerPage=${itemsPerPage}`;
  if (search) query += `&search=${search}`;

  return await apiRequest<ListInboxMessagesDto>(
    "get",
    `/inboxMessages/list${query}`
  );
}


export async function getInboxMessagesByReportUuid(
  reportUuid: string,
): Promise<ReadListInboxMessagesDto[]> {
  let query = `?reportUuid=${reportUuid}`;

  return await apiRequest<ReadListInboxMessagesDto[]>(
    "get",
    `/inboxMessages/list-by-reportUuid${query}`
  );
}


export async function getInboxMessagesListDeletedsAndSearch(
  page: number,
  itemsPerPage: number,
  search?: string
): Promise<ListInboxMessagesDto> {
  let query = `?page=${page}&itemsPerPage=${itemsPerPage}`;
  if (search) query += `&search=${search}`;

  return await apiRequest<ListInboxMessagesDto>(
    "get",
    `/inboxMessages/list-deleteds${query}`
  );
}

export async function getInboxMessagesByUuid(
  uuid: string
): Promise<ReadInboxMessagesDto> {
  return await apiRequest<ReadInboxMessagesDto>(
    "get",
    `/inboxMessages/find-by-uuid?uuid=${uuid}`
  );
}

export async function getInboxMessagesByUserUuid(
  userUuid: string
): Promise<ReadInboxMessagesDto> {
  return await apiRequest<ReadInboxMessagesDto>(
    "get",
    `/inboxMessages/find-by-userUuid?userUuid=${userUuid}`
  );
}

export async function putInboxMessages(
  uuid: string,
  updateInboxMessagesDto: UpdateInboxMessagesDto
): Promise<ReadInboxMessagesDto> {
  return await apiRequest<ReadInboxMessagesDto>(
    "put",
    `/inboxMessages/update?uuid=${uuid}`,
    updateInboxMessagesDto
  );
}

export async function deleteInboxMessages(
  uuid: string
): Promise<ReadInboxMessagesDto> {
  return await apiRequest<ReadInboxMessagesDto>(
    "delete",
    `/inboxMessages/soft-delete?uuid=${uuid}`
  );
}

export async function restoreInboxMessages(
  uuid: string
): Promise<ReadInboxMessagesDto> {
  return await apiRequest<ReadInboxMessagesDto>(
    "post",
    `/inboxMessages/restore?uuid=${uuid}`
  );
}
