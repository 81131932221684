import React, { useContext } from "react";
import Routes from "./Routes";
import { AuthContext } from "contexts/AuthContext";
import { Helmet } from "react-helmet-async";

function App() {
  const { layoutData } = useContext(AuthContext);

  return (
    <>
      <Helmet>
        <title>
          {layoutData && layoutData.companyName
            ? layoutData.companyName
            : "LOQIAI"}
        </title>
        <meta
          name="description"
          content={`${
            layoutData && layoutData.companyName
              ? layoutData.companyName
              : "LOQIAI"
          } reports platform.`}
        />
        <link
          rel="icon"
          href={layoutData && layoutData.logo ? layoutData.logo : undefined}
        />
      </Helmet>
      <Routes />
    </>
  );
}

export default App;
