import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { PrimaryButton } from "components/PrimaryButton";

import { Form, Input, Spin, notification } from "antd";
import React, { useContext, useState } from "react";
import { AuthContext } from "contexts/AuthContext";
import { changePassword } from "services/userService";

export const AdminPasswordInformationsProfileForm = () => {

    const [form] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(false);
    const { user } = useContext(AuthContext);

    const handleChangePassword = async () => {
        try {
            setLoading(true);

            if (user && user.uuid) {
                const response = await changePassword(user.uuid, {
                    oldPassword: form.getFieldValue("oldPassword"),
                    newPassword: form.getFieldValue("newPassword"),
                })

                if (response) {
                    notification.success({
                        placement: "bottomRight",
                        message: "Password changed successfully!",
                    })

                    form.resetFields();
                }
            }

        } catch (error: any) {
            console.error("Error:", error);
            notification.error({
                placement: "bottomRight",
                message: error.response.data.message || "An error has occurred while changing password. Please Try Again",
            })

        } finally {
            setLoading(false)
        }
    }

    return (
        <Form
            form={form}
            layout="vertical"
            autoComplete="off"
            className="w-full flex flex-col gap-5 items-end"
            onFinish={handleChangePassword}
        >
            <Spin tip="Loading..." spinning={loading} fullscreen />

            <div className="w-full grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-3">
                <Form.Item
                    name="oldPassword"
                    label={<span className="font-bold">Actual Password:</span>}
                    rules={[{ required: true, message: "This field is required." }]}
                >
                    <Input.Password
                        id='oldPassword'
                        placeholder="************"
                        className="rounded-md"
                        allowClear
                        size="large"
                        iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    />
                </Form.Item>

                <Form.Item
                    name="newPassword"
                    label={<span className="font-bold">New Password:</span>}
                    rules={[
                        { required: true, message: "This field is required." },
                        { min: 6, message: "Password must be at least 6 characters long." },
                    ]}
                >
                    <Input.Password
                        id='newPassword'
                        placeholder="************"
                        className="rounded-md"
                        allowClear
                        size="large"
                        iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    />
                </Form.Item>

                <Form.Item
                    name="newPasswordConfirmation"
                    label={<span className="font-bold">New Password Confirmation:</span>}
                    dependencies={['newPassword']}
                    rules={
                        [
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('newPassword') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                },
                            }),
                            { required: true, message: "This field is required." },
                            { min: 6, message: "Password must be at least 6 characters long." },
                        ]
                    }
                >
                    <Input.Password
                        id='newPasswordConfirmation'
                        placeholder="************"
                        className="rounded-md"
                        allowClear
                        size="large"
                        iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    />
                </Form.Item>
            </div>

            <PrimaryButton
                className="flex items-center justify-center"
                disabled={loading}
                loading={loading}
                htmlType="submit"
            >
                Change Password
            </PrimaryButton>
        </Form>
    )
}