import React, { useContext, useEffect, useState } from "react";
import LayoutBaseAdmin from "components/LayoutBaseAdmin";
import { Form, Upload, message, Image, GetProp, UploadProps, notification, Spin } from "antd";

import { AuthContext } from "contexts/AuthContext";
import { getUsersByUuid, putUserByUuid } from "services/userService";
import { UploadOutlined } from "@ant-design/icons";
import { postFileProfile } from "services/storageService";
import TitleWithBreadCrumbWithoutBackPage from "components/TitleWithBreadCrumbWithouBackPage";
import { BorderedContentWithTitle } from "components/BorderedContentWithTitle";
import { PrimaryButton } from "components/PrimaryButton";
import { getBase64 } from "utils/getBase64";
import { AdminPersonalInformationsProfileForm } from "components/Forms/AdminProfile/personalInformations";
import { AdminPasswordInformationsProfileForm } from "components/Forms/AdminProfile/passwordInformations";
import { RcFile } from "antd/es/upload";


type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

const Profile: React.FC = () => {
  const [userInfo, setUserInfo] = useState<ReadUsersDto | null>(null);

  const [imageUrl, setImageUrl] = useState<string | undefined>(undefined);
  const [file, setFile] = useState<File | null>(null)

  const { user } = useContext(AuthContext);

  const [loading, setLoading] = useState<boolean>(false);


  const props: UploadProps = {
    name: 'avatar',
    multiple: false,
    accept: '.jpg, .png, .jpeg',
    maxCount: 1,
    showUploadList: false,
    beforeUpload: () => {
      return false;
    },
    onChange({ file, fileList }) {
      if (file.status !== 'uploading') {
        const selectedFile = fileList[0].originFileObj as File;
        setFile(selectedFile);

        const objectUrl = URL.createObjectURL(selectedFile);
        setImageUrl(objectUrl);
      }
    },
  };

  const getUsersData = async () => {
    try {
      if (user && user.uuid) {
        const response = await getUsersByUuid(user.uuid);
        setUserInfo(response);
        setImageUrl(response.profileImageUrl);
      }
    } catch (error) {
      console.error("Error fetching users data:", error);
    }
  };

  useEffect(() => {
    const handleChangeProfileImage = async () => {
      try {
        setLoading(true)

        if (file && userInfo && userInfo.uuid) {
          const response = await postFileProfile(file, userInfo.uuid)
          if (response) {
            notification.success({
              message: "Profile image updated successfully!",
              placement: "bottomRight"
            })
          }
        }

      } catch (error) {
        console.error("Error to post profile image" + error)
        notification.error({
          message: "An error ocurred on change profile image",
          placement: "bottomRight"
        })

      } finally {
        setLoading(false);
      }
    }

    handleChangeProfileImage();

  }, [file])

  useEffect(() => {
    getUsersData();

  }, [user]);

  return (
    <>
      <LayoutBaseAdmin>
        <Spin spinning={loading} fullscreen />

        {userInfo ? (
          <div className="flex flex-col w-full h-full">
            <TitleWithBreadCrumbWithoutBackPage
              actualPage="Profile"
              title="Profile"
            />

            <div className="flex md:flex-row flex-col md:items-start items-center justify-start gap-5">
              <BorderedContentWithTitle
                title="Profile Image"
                className="md:basis-1/12 max-w-[250px]"
              >
                <div className="flex flex-col gap-5">
                  {imageUrl && (
                    <Image
                      className="rounded-xl object-contain"
                      src={imageUrl}
                      alt="Profile Image"
                    />
                  )}

                  <div className="flex justify-center">
                    <Upload {...props} id="profileImage">
                      <PrimaryButton
                        className="flex items-center justify-center"
                        disabled={loading}
                      >
                        <UploadOutlined className="text-[20px]" /> New Profile Image
                      </PrimaryButton>
                    </Upload>
                  </div>
                </div>
              </BorderedContentWithTitle>

              <div className="flex flex-col gap-2 md:basis-11/12 w-full">
                <BorderedContentWithTitle
                  title="Profile Informations"
                >
                  <AdminPersonalInformationsProfileForm />
                </BorderedContentWithTitle>

                <BorderedContentWithTitle
                  title="Password Informations"
                >
                  <AdminPasswordInformationsProfileForm />
                </BorderedContentWithTitle>
              </div>
            </div>
          </div>
        ) : (
          <div>Loading...</div>
        )}
      </LayoutBaseAdmin>
    </>
  );
};

export default Profile;
