import { apiRequest } from "./api";

export async function createTask(taskData: CreateTaskDto) {
  const response = await apiRequest<any>("post", "/tasks/create", taskData);
  return response;
}

export async function deleteTask(taskUuid: string) {
  const response = await apiRequest<any>(
    "delete",
    `/tasks/soft-delete?uuid=${taskUuid}`
  );
  return response;
}

export async function getTasksListAndSearch(
  page: number,
  itemsPerPage: number,
  reportIssuesUuid: string,
  search: string
) {
  const response = await apiRequest<any>(
    "get",
    `/tasks/list-reportIssuesUuid?page=${page}&itemsPerPage=${itemsPerPage}&reportIssuesUuid=${reportIssuesUuid}&search=${search}`
  );
  return response;
}

export async function getTasksAndUsersList(reportIssuesUuid: string) {
  const response = await apiRequest<any>(
    "get",
    `/tasks/list-tasks-users-reportIssuesUuid?reportIssuesUuid=${reportIssuesUuid}`
  );
  return response;
}

export async function updateTask(taskUuid: string, taskData: UpdateTaskDto) {
  const response = await apiRequest<any>(
    "put",
    `/tasks/update?uuid=${taskUuid}`,
    taskData
  );
  return response;
}

export async function updateTaskStatus(
  taskUuid: string,
  taskStatusUuid: string
) {
  const response = await apiRequest<any>(
    "put",
    `/tasks/update-status?uuid=${taskUuid}`,
    { taskStatusUuid }
  );
  return response;
}

export async function updateTaskUsers(
  taskUuid: string,
  requestData: { usersUuids: string[] }
) {
  const response = await apiRequest<any>(
    "put",
    `/tasks/update-users?uuid=${taskUuid}`,
    requestData
  );
  return response;
}

export const deleteUserTask = async (userUuid: string, taskUuid: string) => {
  const response = await apiRequest<any>(
    "delete",
    `/tasks/delete-user-task?userUuid=${userUuid}&taskUuid=${taskUuid}`
  );
  return response;
};
