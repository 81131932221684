import { apiRequest } from "./_api";

export async function postLayout(
  postLayoutDto: CreateLayoutDto
): Promise<ReadLayoutDto> {
  return await apiRequest<ReadLayoutDto>(
    "post",
    `/layouts/create`,
    postLayoutDto
  );
}

export async function getLayoutListAndSearch(
  page: number,
  itemsPerPage: number,
  search?: string
): Promise<ListLayoutsDto> {
  let query = "?";
  query += page ? `page=${page}&` : "";
  query += itemsPerPage ? `itemsPerPage=${itemsPerPage}&` : "";
  query += search ? `search=${search}` : "";

  return await apiRequest<ListLayoutsDto>(
    "get",
    `/layouts/list${query}`
  );
}

export async function getLayoutData(): Promise<ReadLayoutDto> {
  return await apiRequest<ReadLayoutDto>(
    "get",
    `/layouts/find-json-by-uuid`
  );
}


export async function getLayoutByDomain(): Promise<ReadLayoutDto> {
  return await apiRequest<ReadLayoutDto>(
    "get",
    `/layouts/find-layout`
  );
}

export async function getLayoutListByUserUuidAndSearch(
  page: number,
  itemsPerPage: number,
  companyHeadUuid: string,
  search?: string
): Promise<ListLayoutsDto> {
  let query = "?";
  query += page ? `page=${page}&` : "";
  query += itemsPerPage ? `itemsPerPage=${itemsPerPage}&` : "";
  query += companyHeadUuid ? `companyHeadUuid=${companyHeadUuid}&` : "";
  query += search ? `search=${search}` : "";

  return await apiRequest<ListLayoutsDto>(
    "get",
    `/layouts/list-by-userUuid${query}`
  );
}

export async function getLayoutByUuid(uuid: string): Promise<ReadLayoutDto> {
  let query = "?";
  query += uuid ? `uuid=${uuid}` : "";

  return await apiRequest<ReadLayoutDto>(
    "get",
    `/layouts/find-by-uuid${query}`
  );
}

export async function putLayout(
  uuid: string,
  putLayoutDto: UpdateLayoutDto
): Promise<ReadLayoutDto> {
  let query = "?";
  query += uuid ? `uuid=${uuid}` : "";

  return await apiRequest<ReadLayoutDto>(
    "put",
    `/layouts/update${query}`,
    putLayoutDto
  );
}

export async function deleteLayout(uuid: string): Promise<DeleteDto> {
  let query = "?";
  query += uuid ? `uuid=${uuid}` : "";

  return await apiRequest<DeleteDto>(
    "delete",
    `/layouts/soft-delete${query}`
  );
}
