import { apiRequest } from "./_api";

export async function postNotification(
  createNotificationDto: CreateNotificationDto
): Promise<ReadNotificationDto> {
  return await apiRequest<ReadNotificationDto>(
    "post",
    `/notifications/create`,
    createNotificationDto
  );
}

export async function getNotificationsViewByUserUuid(
  userUuid: string
): Promise<ListNotificationsDto> {
  let query = `?userUuid=${userUuid}`;
  
  return await apiRequest<ListNotificationsDto>(
    "get",
    `/notifications/find-notifications-view-by-userUuid${query}`
  );
}

export async function getNotificationsListAndSearch(
  page: number,
  itemsPerPage: number,
  search?: string
): Promise<ListNotificationsDto> {
  let query = "?";
  query += page ? `page=${page}&` : "";
  query += itemsPerPage ? `itemsPerPage=${itemsPerPage}&` : "";
  query += search ? `search=${search}` : "";

  return await apiRequest<ListNotificationsDto>(
    "get",
    `/notifications/list${query}`
  );
}

export async function getNotificationsListAndSearchByUserUuid(
  page: number,
  itemsPerPage: number,
  userUuid: string,
  search?: string
): Promise<ListNotificationsDto> {
  let query = "?";
  query += page ? `page=${page}&` : "";
  query += itemsPerPage ? `itemsPerPage=${itemsPerPage}&` : "";
  query += search ? `search=${search}` : "";
  query += userUuid? `userUuid=${userUuid}` : "";
  
  return await apiRequest<ListNotificationsDto>(
    "get",
    `/notifications/list-by-userUuid${query}`
  );
}

export async function getByUuidNotification(
  uuid: string
): Promise<ReadNotificationDto> {
  let query = "?";
  query += uuid ? `uuid=${uuid}` : "";

  return await apiRequest<ReadNotificationDto>(
    "get",
    `/notifications/find-by-uuid${query}`
  );
}

export async function putNotificationViewed(
  updateNotificationViewedDto: UpdateNotificationViewedDto
): Promise<boolean> {
  return await apiRequest<boolean>(
    "put",
    `/notifications/update-viewed`,
    updateNotificationViewedDto
  );
}

// DELETE Notification
export async function deleteNotification(uuid: string): Promise<DeleteDto> {
  let query = "?";
  query += uuid ? `uuid=${uuid}` : "";

  return await apiRequest<DeleteDto>(
    "delete",
    `/notifications/soft-delete${query}`
  );
}
