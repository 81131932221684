import { apiRequest } from "./_api";

export async function getUsersListAndSearch(
  page: number,
  itemsPerPage: number,
  search?: string
): Promise<ListUsersDto> {
  let query = "?";
  query += page ? `page=${page}&` : "";
  query += itemsPerPage ? `itemsPerPage=${itemsPerPage}&` : "";
  query += search ? `search=${search}` : "";

  return await apiRequest<ListUsersDto>("get", `/users/list${query}`);
}

export async function getUsersListAndSearchByCompanyUuid(
  companyUuid: string,
  page: number,
  itemsPerPage: number,
  search?: string
): Promise<ListUsersDto> {
  let query = "?";
  query += companyUuid ? `companyUuid=${companyUuid}&` : "";
  query += page ? `page=${page}&` : "";
  query += itemsPerPage ? `itemsPerPage=${itemsPerPage}&` : "";
  query += search ? `search=${search}` : "";

  return await apiRequest<ListUsersDto>(
    "get",
    `/users/list-by-company${query}`
  );
}

export async function getAllUsersListAndSearch(
  page: number,
  itemsPerPage: number,
  search?: string
): Promise<ListAllUsersDto> {
  let query = "?";
  query += page ? `page=${page}&` : "";
  query += itemsPerPage ? `itemsPerPage=${itemsPerPage}&` : "";
  query += search ? `search=${search}` : "";

  return await apiRequest<ListAllUsersDto>(
    "get",
    `/users/list-all-users${query}`
  );
}

export async function getUsersByUuid(uuid: string): Promise<ReadUsersDto> {
  let query = "?";
  query += uuid ? `uuid=${uuid}` : "";

  return await apiRequest<ReadUsersDto>("get", `/users/find-by-uuid${query}`);
}

export async function viewUserPasswordByUuid(uuid: string): Promise<string> {
  let query = "?";
  query += uuid ? `uuid=${uuid}` : "";

  return await apiRequest<string>("get", `/users/view-user-password${query}`);
}

export async function getUsersByUuidByCompany(
  uuid: string,
  companyUuid: string
): Promise<ReadUsersDto> {
  let query = "?";
  query += uuid ? `uuid=${uuid}&` : "";
  query += companyUuid ? `companyUuid=${companyUuid}` : "";

  return await apiRequest<ReadUsersDto>(
    "get",
    `/users/company/find-by-uuid${query}`
  );
}

export async function getUserByEmail(email: string): Promise<ReadUsersDto> {
  let query = "?";
  query += email ? `email=${email}` : "";

  return await apiRequest<ReadUsersDto>("get", `/users/find-by-email${query}`);
}

export async function selectUsers(): Promise<SelectUsersDto[]> {
  return await apiRequest<SelectUsersDto[]>("get", `/users/select`);
}

export async function getUsersByType(type: string): Promise<ReadUsersDto[]> {
  let query = "?";
  query += type ? `type=${type}` : "";

  return await apiRequest<ReadUsersDto[]>("get", `/users/find-by-type${query}`);
}

export async function putUserByUuid(
  uuid: string,
  putUserByUuidDto: UpdateUsersDto
): Promise<ReadUsersDto> {
  let query = "?";
  query += uuid ? `uuid=${uuid}` : "";

  return await apiRequest<ReadUsersDto>(
    "put",
    `/users/update${query}`,
    putUserByUuidDto
  );
}

export async function changePassword(
  uuid: string,
  putUserByUuidDto: ChangePasswordDto
): Promise<ReadUsersDto> {
  let query = "?";
  query += uuid ? `uuid=${uuid}` : "";

  return await apiRequest<ReadUsersDto>(
    "put",
    `/users/update-password${query}`,
    putUserByUuidDto
  );
}

export async function putUserByUuidByCompanyUuid(
  uuid: string,
  companyUuid: string,
  putUserByUuidDto: UpdateUsersDto
): Promise<ReadUsersDto> {
  let query = "?";
  query += uuid ? `uuid=${uuid}&` : "";
  query += companyUuid ? `companyUuid=${companyUuid}` : "";

  return await apiRequest<ReadUsersDto>(
    "put",
    `/users/company/update${query}`,
    putUserByUuidDto
  );
}

export async function deleteUserByUuid(uuid: string): Promise<DeleteDto> {
  let query = "?";
  query += uuid ? `uuid=${uuid}` : "";

  return await apiRequest<DeleteDto>("delete", `/users/soft-delete${query}`);
}

export async function deleteUserByUuidByCompany(
  uuid: string,
  companyUuid: string
): Promise<DeleteDto> {
  let query = "?";
  query += uuid ? `uuid=${uuid}&` : "";
  query += companyUuid ? `companyUuid=${companyUuid}` : "";

  return await apiRequest<DeleteDto>(
    "delete",
    `/users/soft-delete/company${query}`
  );
}
