import React, { useContext, useEffect, useState } from "react";

import {
  Button,
  Form,
  Input,
  DatePicker,
  notification,
  Spin,
} from "antd";
import { useNavigate } from "react-router-dom";
import TitleWithBreadCrumb from "components/TitleWithBreadCrumb";
import { ExclamationOutlined } from "@ant-design/icons";
import LayoutBaseAdmin from "components/LayoutBaseAdmin";
import TextArea from "antd/es/input/TextArea";
import queryString from "query-string";
import {
  createReportIssue,
  getReportIssueByUuid,
  updateReportIssue,
} from "services/reportIssuesService";
import dayjs from "dayjs";
import { formatDate, formatFormalDate } from "utils/formatDate";
import { getTasksAndUsersList } from "services/taskService";
import { getReportByCodeAdmin } from "services/reportService";
import { AuthContext } from "contexts/AuthContext";
import LayoutBaseBusiness from "components/SuperAdminAndBusiness/LayoutBaseBusiness";


interface UserTask {
  uuid: string;
  name: string;
}

const OpenInvestigationPage: React.FC = () => {
  const [arrow, setArrow] = useState("Show");
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { code } = queryString.parse(window.location.search);
  const { issue } = queryString.parse(window.location.search);

  const { user } = useContext(AuthContext);

  const [loading, setLoading] = useState<boolean>(false);
  const [reportData, setReportData] = useState<any>([]);
  const [reportId, setReportId] = useState<string>("");
  const [reportIssueUuid, setReportIssueUuid] = useState<string>("");

  const [reportIssueData, setReportIssueData] = useState<any>({});

  const [usersOnTask, setUsersOnTask] = useState<UserTask[]>([]);

  const getReportIssueData = async () => {
    try {
      setLoading(true);

      if (typeof issue === "string") {
        const response = await getReportIssueByUuid(issue);
        if (response) {
          setReportIssueData(response);
          setReportIssueUuid(response.uuid);
          setReportId(response.reportUuid);

          const formattedReportedDate = response.reportedDate;

          const formattedallegedIncidentDate = response.allegedIncidentDate
            ? dayjs(response.allegedIncidentDate)
            : null;
          const formatteddueDate = response.dueDate
            ? dayjs(response.dueDate)
            : null;

          form.setFieldsValue({
            ...response,
            reportedDate: formattedReportedDate,
            dueDate: formatteddueDate,
            allegedIncidentDate: formattedallegedIncidentDate,
          });
        }
      }
    } catch (error) {
      console.error("Error:", error);

    } finally {
      setLoading(false);
    }

  };

  const getTaskAndUsersData = async () => {
    try {
      setLoading(true);

      if (typeof issue === "string") {
        const response = await getTasksAndUsersList(issue);

        if (response && response.data && Array.isArray(response.data)) {
          const extractedUsers = response.data
            .map((task) => task.users || [])
            .reduce((acc, users) => acc.concat(users), []);

          if (extractedUsers.length > 0) {
            setUsersOnTask(extractedUsers);

            const allUserNames = extractedUsers.map((user) => user.name);

            form.setFieldsValue({
              caseAssignees: allUserNames,
            });
          } else {
            console.error("Nenhum usuário foi encontrado.");
          }
        } else {
          console.error("Resposta inválida.");
        }
      }
    } catch (error) {
      console.error("Error:", error);

    } finally {
      setLoading(false);
    }
  };

  const getReportData = async () => {
    try {
      setLoading(true);

      if (typeof code === "string") {
        const response = await getReportByCodeAdmin(code);
        setReportData(response);
        setReportId(response.uuid);
      }
    } catch (error) {
      console.error("Error:", error);
      notification.error({
        placement: "bottomRight",
        message: "Error",
        description: "An error occurred during the operation.",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getReportIssueData();
    getTaskAndUsersData();
  }, []);

  const handleUpdate = async (values) => {
    try {
      const witnesses = values?.witnesses;
      const location = values?.location;
      const title = values.title;
      const summary = values.summary;

      const reportIssueData = {
        witnesses,
        location,
        title,
        summary,
      };
      const response = await updateReportIssue(
        reportIssueUuid,
        reportIssueData
      );

      if (response) {
        notification.success({
          placement: "bottomRight",
          message: "Report Issue updated with success!",
          duration: 3,
        });
        window.location.reload();
      }
    } catch (error) {
      console.error("Error:", error);
      notification.error({
        placement: "bottomRight",
        message: "Error",
        description: "An error occurred while submitting the comment.",
      });
    }
  };
  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      values.reportUuid = reportId;
      values.reportedDate = dayjs(values.reportedDate).format();
      values.allegedIncidentDate = dayjs(values.allegedIncidentDate).format();
      values.dueDate = dayjs(values.dueDate).format();

      const response = await createReportIssue(values);

      if (response) {
        notification.success({
          placement: "bottomRight",
          message: "Investigation opened successfully.",
          duration: 3,
        });
        navigate(`/${user && user.type === 'supervisor' ? 'business' : 'analyst'}/reports`);
      } else {
        notification.error({
          message: "There is a problem, check the information.",
          placement: "bottomRight",
          duration: 3,
        });
      }
    } catch (error) {
      console.error("Validation failed:", error);
    }
  };

  const LayoutComponent = user && user.type === 'supervisor' ? LayoutBaseBusiness : LayoutBaseAdmin;

  return (
    <>
      <LayoutComponent>
        <Spin spinning={loading} fullscreen />

        <div className="flex flex-col h-full w-full">
          <TitleWithBreadCrumb
            actualPage="Open Investigation"
            title="Open Investigation"
            backPage={`${reportIssueData.caseIssue
              ? reportIssueData.caseIssue
              : reportIssueData.caseNumber
              }`}
            navigateTo={`/${user && user.type === 'supervisor' ? 'business': 'analyst'}/report/issue?issue=${issue}`}
          />
            <Form
              form={form}
              className="flex flex-col gap-5 w-full mt-10"
              layout="vertical"
              onFinish={handleUpdate}
            >
              <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-3">
                <Form.Item
                  className="md:w-full"
                  name="caseNumber"
                  label={
                    <div className="text-base font-semibold">Case Number</div>
                  }
                >
                  <Input
                    defaultValue={reportIssueData.caseNumber}
                    disabled
                    id="caseNumber"
                    className="w-full rounded-md"
                    size="large"
                  />
                </Form.Item>
                <Form.Item
                  className="md:w-full"
                  name="reportedDate"
                  label={
                    <div className="text-base font-semibold">Reported Date</div>
                  }
                >
                  <Input
                    placeholder="Select a date"
                    id="reportedDate"
                    size="large"
                    className="w-full rounded-md"
                  />
                </Form.Item>
                {/* Adicione os outros 2 Form.Items aqui */}
                <Form.Item
                  className="md:w-full"
                  name="allegedIncidentDate"
                  label={
                    <div className="text-base font-semibold">
                      Alleged Incident Date
                    </div>
                  }
                >
                  <DatePicker
                    placeholder="Select a date"
                    id="allegedIncidentDate"
                    size="large"
                    className="w-full h-[40px]"
                    allowClear
                    format={"MM/DD/YYYY"}
                  />
                </Form.Item>
                <Form.Item
                  className="md:w-full"
                  name="dueDate"
                  label={<div className="text-base font-semibold">Due Date</div>}
                >
                  <DatePicker
                    placeholder="Select a date"
                    id="dueDate"
                    size="large"
                    className="w-full h-[40px]"
                    allowClear
                    format={"MM/DD/YYYY"}
                  />
                </Form.Item>
                <Form.Item
                  className="md:w-full"
                  name="location"
                  label={<div className="text-base font-semibold">Location</div>}
                >
                  <Input
                    id="location"
                    size="large"
                    placeholder="Type Here"
                    className="w-full rounded-md"
                    allowClear
                  />
                </Form.Item>
                <Form.Item
                  className="md:w-full"
                  name="caseIssue"
                  label={
                    <div className="text-base font-semibold">Issue Category</div>
                  }
                >
                  <Input
                    disabled
                    id="caseIssue"
                    size="large"
                    placeholder="Type Here"
                    className="w-full rounded-md"
                    allowClear
                  />
                </Form.Item>
                <Form.Item
                  className="md:w-full"
                  name={["reportIssuePriorities", "priority"]}
                  label={
                    <div className="text-base font-semibold">
                      Assigned Risk Tier
                    </div>
                  }
                >
                  <Input
                    disabled
                    id="reportIssuePriorities.priority"
                    size="large"
                    placeholder="Type Here"
                    className="w-full rounded-md"
                    allowClear
                  />
                </Form.Item>
                <Form.Item
                  className="md:w-full"
                  name="witnesses"
                  label={<div className="text-base font-semibold">Witnesses</div>}
                >
                  <Input
                    disabled
                    id="witnesses"
                    size="large"
                    placeholder="Type Here"
                    className="w-full rounded-md"
                    allowClear
                  />
                </Form.Item>
              </div>

              <div className="w-full">
                <Form.Item
                  name="summary"
                  label={<div className="text-base font-semibold">Summary</div>}
                >
                  <Input.TextArea
                    rows={5}
                    id="summary"
                    size="large"
                    placeholder="Type Here"
                    className="w-full rounded-md"
                    allowClear
                  />
                </Form.Item>
              </div>

              <div className="w-full flex items-center justify-end gap-3">
                <Button
                  size="large"
                  shape="round"
                  className="border text-blue_gray-400 border-solid cursor-pointer min-w-[102px] text-center"
                  onClick={() => navigate(`/${user && user.type === 'supervisor' ? 'business' : 'analyst'}/report/issue?issue=${issue}`)}
                >
                  Cancel
                </Button>
                <Button
                  className="min-w-[102px] text-center"
                  htmlType="submit"
                  shape="round"
                  size="large"
                  style={{ background: 'var(--primary_color)', color: 'var(--white_A700)' }}
                >
                  Open Investigation
                </Button>
              </div>
            </Form>
          </div>
      </LayoutComponent >
    </>
  );
};

export default OpenInvestigationPage;
