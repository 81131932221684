import { Breadcrumb } from "antd";
import { Text } from "components/Text";
import React from "react";


interface TitleWithBreadCrumbProps {
    actualPage: string;
    title: string;
};

const TitleWithBreadCrumbWithoutBackPage = ({
    actualPage,
    title,

}: TitleWithBreadCrumbProps) => {
    return (
        <div className="flex flex-col gap-3  ">
            <Breadcrumb
                items={[
                    {
                        title: actualPage,
                    },
                ]}
            />

            <div className="flex items-center gap-2">
                <Text
                    className="text-[32px] text-blue_gray-900 font-semibold"
                >
                    {title}
                </Text>
            </div>
        </div>
    );
};

export default TitleWithBreadCrumbWithoutBackPage;
