import { apiRequest } from "./api";

export async function createReport(reportData: CreateReportDto) {
  const response = await apiRequest<any>("post", "/reports/create", reportData);
  return response;
}

export async function postDeleteReportRequest(
  reportData: DeleteReportRequestDto
) {
  const response = await apiRequest<any>(
    "post",
    "/reports/delete-request",
    reportData
  );
  return response;
}

export async function generateReport(uuid: string) {
  const response = await apiRequest<any>(
    "get",
    `/reports/generate?uuid=${uuid}`
  );
  return response;
}

export async function getReportByUuid(uuid: string) {
  const response = await apiRequest<any>(
    "get",
    `/reports/find-by-uuid?uuid=${uuid}`
  );
  return response;
}

export async function getReportByCode(code: string) {
  const response = await apiRequest<any>(
    "get",
    `/reports/find-by-code?code=${code}`
  );
  return response;
}

export async function selectReports() {
  const response = await apiRequest<any>("get", `/reports/select`);
  return response;
}

export async function getReportByCodeAdmin(code: string) {
  const response = await apiRequest<any>(
    "get",
    `/reports/find-by-code-admin?code=${code}`
  );
  return response;
}

export async function getUsersByReportUuid(reportUuid: string) {
  const response = await apiRequest<ReadUsersDto[]>(
    "get",
    `/reports/users-by-reportUuid?reportUuid=${reportUuid}`
  );
  return response;
}

export async function getReportsListAndSearch(
  page: number,
  itemsPerPage: number,
  search: string
) {
  const response = await apiRequest<any>(
    "get",
    `/reports/list?page=${page}&itemsPerPage=${itemsPerPage}&search=${search}`
  );
  return response;
}

export async function getDeleteRequestListAndSearch(
  page: number,
  itemsPerPage: number,
  search?: string
): Promise<ListDeleteReportRequestDto> {
  let query = "?";
  query += page ? `page=${page}&` : "";
  query += itemsPerPage ? `itemsPerPage=${itemsPerPage}&` : "";
  query += search ? `search=${search}` : "";

  return await apiRequest<ListDeleteReportRequestDto>(
    "get",
    `/reports/list-delete-requests${query}`
  );
}

export async function generatePromptTest(
  promptUuid: string,
  subject: string,
  text: string
): Promise<GenerateTestPromptDto> {
  let query = "?";
  query += promptUuid ? `promptUuid=${promptUuid}&` : "";
  query += subject ? `subject=${subject}&` : "";
  query += text ? `text=${text}` : "";

  return await apiRequest<GenerateTestPromptDto>(
    "get",
    `/reports/generate-test${query}`
  );
}

export async function updateReport(uuid: string, reportData: UpdateReportDto) {
  const response = await apiRequest<any>(
    "put",
    `/reports/update?uuid=${uuid}`,
    reportData
  );
  return response;
}

export async function updateDeleteRequestReport(
  uuid: string,
  reportData: UpdateReportDeleteRequestDto
) {
  const response = await apiRequest<any>(
    "put",
    `/reports/update-delete-request?uuid=${uuid}`,
    reportData
  );
  return response;
}

export async function updateReportWasRead(uuid: string) {
  const response = await apiRequest<any>(
    "put",
    `/reports/update-wasRead?uuid=${uuid}`
  );
  return response;
}

export async function assignUsersReport(
  uuid: string,
  data: AssignUsersReportDto
) {
  const response = await apiRequest<any>(
    "put",
    `/reports/update-add-users?uuid=${uuid}`,
    data
  );
  return response;
}

export async function updateReportStatus(
  reportUuid: string,
  reportStatusUUID: string
) {
  const response = await apiRequest<any>(
    "put",
    `/reports/update-reportStatus?uuid=${reportUuid}`,
    { reportStatusUUID }
  );
  return response;
}

export async function updateReportIssuePriority(
  reportUuid: string,
  reportIssuePriorityUUID: string
) {
  const response = await apiRequest<any>(
    "put",
    `/reports/update-reportIssuePriority?uuid=${reportUuid}`,
    { reportIssuePriorityUUID }
  );
  return response;
}

export async function generateReportIssuesByReportUuid(reportUuid: string) {
  const response = await apiRequest<any>(
    "get",
    `/reports/generate?uuid=${reportUuid}`
  );
  return response;
}

export async function deleteReport(uuid: string) {
  const response = await apiRequest<any>(
    "delete",
    `/reports/soft-delete?uuid=${uuid}`
  );
  return response;
}
