import React from "react";
import { Text } from 'components'
import { PrimaryButton } from "components/PrimaryButton";

type Props = {
    children: React.ReactNode;
    title: string;
    className?: string;
    hasButton?: boolean;
    buttonText?: string;
    buttonIcon?: React.ReactNode;
    onClick?: () => void;
}

export const BorderedContentWithTitle = ({ children, title, className, hasButton = false, buttonText, buttonIcon, onClick }: Props) => {
    return (
        <div
            className={`border blue-gray_50 border-solid rounded-lg p-3   mt-7 ${className}`}
        >
            <div className="flex justify-between items-center flex-wrap">
                <Text
                    className="text-base text-blue_gray-400 mb-5"
                >
                    {title}
                </Text>

                {hasButton && (
                    <PrimaryButton 
                        onClick={onClick}
                        className="flex items-center"
                    >
                        {buttonText}
                        {buttonIcon}
                    </PrimaryButton>
                )}
            </div>

            {children}

        </div>
    )
}