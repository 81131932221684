import React from "react";
import "./styles/color.css";
import "./styles/font.css";
import ReactDOM from "react-dom";
import App from "./App";
import "./styles/index.css";
import "./styles/tailwind.css";
import { ConfigProvider } from "antd";
import { AuthProvider } from "contexts/AuthContext";
import { HelmetProvider } from "react-helmet-async";

ReactDOM.render(
  <React.StrictMode>
    <ConfigProvider
      theme={{
        components: {
          Select: {
            borderRadius: 6,
            borderRadiusLG: 50
          },
          Button: {
            colorText: "#000"
          }
        }
      }}
    >
      <HelmetProvider>
        <AuthProvider>
          <App />
        </AuthProvider>
      </HelmetProvider>
    </ConfigProvider>
  </React.StrictMode>,
  document.getElementById("root"),
);
