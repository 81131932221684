import { Breadcrumb, Button } from "antd";
import { Text } from "components/Text";
import { CornerDownLeft } from "lucide-react";
import React from "react";
import { useNavigate } from "react-router-dom";

interface TitleWithBreadCrumbProps {
    backPage: string
    actualPage: string
    title: string
    navigateTo: string
}

const TitleWithBreadCrumb = ({ backPage, actualPage, title, navigateTo }: TitleWithBreadCrumbProps) => {

    const navigation = useNavigate();

    return (
        <div className="flex flex-col gap-3">
            <Breadcrumb
                items={[
                    {
                        title: <a href={navigateTo}>{backPage}</a>,
                    },
                    {
                        title: actualPage,
                    },
                ]}
            />

            <div className="flex items-center gap-2  ">
                <Button
                    shape="circle"
                    size="large"
                    icon={<CornerDownLeft style={{ color: 'var(--primary_color)' }} />}
                    onClick={() => navigation(navigateTo)}
                />
                <Text
                    className="text-[32px] text-blue_gray-900 font-semibold"
                >
                    {title}
                </Text>
            </div>
        </div>
    )
}

export default TitleWithBreadCrumb;