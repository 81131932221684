import React from "react"
import { Button } from "antd"

type Props = {
    children: React.ReactNode
    className?: string
    onClick?: () => void
    loading?: boolean
    disabled?: boolean
    htmlType?: "submit" | "reset" | "button"
}

export const PrimaryButton = ({ children, className, onClick, loading, disabled, htmlType }: Props) => {
    return (
        <Button
            onClick={onClick}
            className={`${className}`}
            style={{background: 'var(--primary_color)', color: 'var(--white_A700)'}}
            shape="round"
            size="large"
            type="default"
            loading={loading}
            disabled={disabled}
            htmlType={htmlType}
        >
            {children}
        </Button>
    )
}