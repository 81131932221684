import React from "react";

const sizeClasses = {
  txtNunitoBold32: "font-bold  ",
  txtNunitoMedium14Bluegray400_1: "font-medium  ",
  txtDMSansBold1422Black900: "font-bold font-dmsans",
  txtNunitoRegular16Bluegray200: "font-normal  ",
  txtNunitoRegular16Bluegray400: "font-normal  ",
  txtNunitoSemiBold16Bluegray900: "  font-semibold",
  txtNunitoMedium40: "font-medium  ",
  txtDMSansBold1422: "font-bold font-dmsans",
  txtNunitoSemiBold16: "  font-semibold",
  txtNunitoRegular14Bluegray900: "font-normal  ",
  txtNunitoSemiBold14: "  font-semibold",
  txtNunitoSemiBold12: "  font-semibold",
  txtSofiaProMedium121: "font-medium font-sofiapro",
  txtNunitoSemiBold12IndigoA400: "  font-semibold",
  txtNunitoRegular12: "font-normal  ",
  txtNunitoRegular14: "font-normal  ",
  txtNunitoRegular16: "font-normal  ",
  txtRubikRegular16: "font-normal font-rubik",
  txtNunitoRegular18: "font-normal  ",
  txtInterMedium14: "font-inter font-medium",
  txtNunitoSemiBold16WhiteA700: "  font-semibold",
  txtNunitoSemiBold14Bluegray900: "  font-semibold",
  txtPlusJakartaSansSemiBold14: "font-plusjakartasans font-semibold",
  txtNunitoBold16Bluegray900: "font-bold  ",
  txtNunitoMedium16WhiteA700: "font-medium  ",
  txtNunitoMedium14Bluegray400: "font-medium  ",
  txtInterRegular14: "font-inter font-normal",
  txtNunitoMedium1422Bluegray40001: "font-medium  ",
  txtNunitoMedium12: "font-medium  ",
  txtNunitoRegular12Black900: "font-normal  ",
  txtNunitoRegular12Bluegray400: "font-normal  ",
  txtNunitoMedium14Gray60001: "font-medium  ",
  txtNunitoMedium15: "font-medium  ",
  txtNunitoMedium16: "font-medium  ",
  txtNunitoBold16: "font-bold  ",
  txtNunitoBold22Gray900: "font-bold  ",
  txtNunitoMedium13: "font-medium  ",
  txtNunitoBold14: "font-bold  ",
  txtNunitoMedium14: "font-medium  ",
  txtNunitoSemiBold14Lightblue400: "  font-semibold",
  txtNunitoRegular14Gray800: "font-normal  ",
  txtNunitoBold14Bluegray800: "font-bold  ",
  txtNunitoBold18: "font-bold  ",
  txtNunitoSemiBold12Lightblue400: "  font-semibold",
  txtNunitoMedium14Lightgreen900: "font-medium  ",
  txtNunitoRegular20: "font-normal  ",
  txtNunitoExtraBold2101: "font-extrabold  ",
  txtManropeSemiBold14: "font-manrope font-semibold",
  txtNunitoLight14: "font-light  ",
  txtNunitoMedium14Bluegray40002: "font-medium  ",
  txtNunitoRegular14Black900: "font-normal  ",
  txtNunitoMedium16Bluegray400: "font-medium  ",
  txtNunitoSemiBold16Lightblue400: "  font-semibold",
  txtNunitoSemiBold16Bluegray200: "  font-semibold",
  txtNunitoBold22: "font-bold  ",
  txtNunitoBold20: "font-bold  ",
  txtNunitoBold26: "font-bold  ",
  txtNunitoBold24: "font-bold  ",
  txtNunitoBold16Lightblue400: "font-bold  ",
  txtNunitoExtraBold14: "font-extrabold  ",
  txtPlusJakartaSansRegular12: "font-normal font-plusjakartasans",
  txtNunitoBold28: "font-bold  ",
  txtNunitoRegular14Bluegray200: "font-normal  ",
  txtNunitoSemiBold16Lightgreen900: "  font-semibold",
  txtNunitoRegular14Bluegray400: "font-normal  ",
  txtNunitoBold14WhiteA700: "font-bold  ",
  txtNunitoMedium1422: "font-medium  ",
  txtNunitoRegular18Bluegray900: "font-normal  ",
  txtNunitoExtraBold1681: "font-extrabold  ",
  txtNunitoMedium14Bluegray500: "font-medium  ",
  txtNunitoBold200: "font-bold  ",
  txtNunitoSemiBold1422: "  font-semibold",
  txtNunitoSemiBold12Lightgreen900: "  font-semibold",
  txtNumansRegular13: "font-normal font-numans",
} as const;

export type TextProps = Partial<{
  className: string;
  size: keyof typeof sizeClasses;
  as: any;
}> &
  React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLSpanElement>,
    HTMLSpanElement
  >;

const Text: React.FC<React.PropsWithChildren<TextProps>> = ({
  children,
  className = "",
  size,
  as,
  ...restProps
}) => {
  const Component = as || "p";

  return (
    <Component
      className={`text-left ${className} ${size && sizeClasses[size]}`}
      {...restProps}
    >
      {children}
    </Component>
  );
};

export { Text };
