import { CreateReportIssueDto, UpdateReportIssueDto } from "types/reportIssue";
import { apiRequest } from "./api";

export async function createReportIssue(data: CreateReportIssueDto) {
  const response = await apiRequest<any>("post", "/reportIssues/create", data);
  return response;
}

export async function getReportIssueByUuid(uuid: string) {
  if (uuid) {
    const response = await apiRequest<any>(
      "get",
      `/reportIssues/find-by-uuid?uuid=${uuid}`
    );
    return response;
  }
}

export async function addAnalystToReportIssue(
  reportUuid: string,
  userUuid: string
) {
  if (reportUuid && userUuid) {
    const response = await apiRequest<any>(
      "get",
      `/reportIssues/add-involvedFunctions-by-reportUuid?reportUuid=${reportUuid}&userUuid=${userUuid}`
    );
    return response;
  }
}

export async function removeAnalystToReportIssue(
  reportUuid: string,
  userUuid: string
) {
  if (reportUuid && userUuid) {
    const response = await apiRequest<any>(
      "get",
      `/reportIssues/remove-involvedFunctions-by-reportUuid?reportUuid=${reportUuid}&userUuid=${userUuid}`
    );
    return response;
  }
}

export async function getReportIssueByReportUuid(reportUuid: string) {
  if (reportUuid) {
    const response = await apiRequest<any>(
      "get",
      `/reportIssues/find-by-reportUuid?reportUuid=${reportUuid}`
    );
    return response;
  }
}

export async function closeReportIssue(reportIssueUuid: string) {
  if (reportIssueUuid) {
    const response = await apiRequest<any>(
      "put",
      `/reportIssues/closed?uuid=${reportIssueUuid}`
    );
    return response;
  }
}

export async function updateReportIssue(
  uuid: string,
  data: UpdateReportIssueDto
) {
  if (uuid) {
    const response = await apiRequest<any>(
      "put",
      `/reportIssues/update?uuid=${uuid}`,
      data
    );
    return response;
  }
}
